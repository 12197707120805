/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import semver from 'semver';
import { useFeatureFlag } from '@/features/globalConfig';
import { useAppState } from '@/stores/AppState';
import { GlobalConfig } from '@/features/globalConfig';

export function compareVersions(appVersion: string, minVersion: string): boolean | string | null {
  const isMobileAppVersionValid = semver.valid(semver.coerce(appVersion)); // x.x.x format only
  const isMinimumMobileVersionValid = semver.valid(semver.coerce(minVersion)); // x.x.x format only
  // Use semver's gte function to check if isMobileAppVersionValid is greater than or equal to isMinimumMobileVersionValid
  return (
    isMobileAppVersionValid &&
    isMinimumMobileVersionValid &&
    semver.gte(isMobileAppVersionValid, isMinimumMobileVersionValid)
  );
}

export const useIsValidMobileAppVersion = (
  flagWithVersionNumber: keyof GlobalConfig
): boolean | string | null => {
  let { mobileAppVersion, isMobileApp } = useAppState().state;
  let mobileAppStreamlineFlowVersion = useFeatureFlag(flagWithVersionNumber);

  const isValid =
    isMobileApp && compareVersions(mobileAppVersion!, mobileAppStreamlineFlowVersion as string);
  return isValid;
};

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const RepairShop = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M21.919 7.606L18.919 0.606C18.762 0.238 18.4 0 18 0H3.99996C3.59996 0 3.23796 0.238 3.08096 0.606L0.0809638 7.606C-0.0520362 7.914 -0.0200362 8.269 0.164964 8.55C0.349964 8.832 0.663964 9 0.999964 9H1.99996V17C1.99996 17.552 2.44696 18 2.99996 18H13V11H17V18H19C19.553 18 20 17.552 20 17V9H21C21.336 9 21.65 8.832 21.835 8.551C22.02 8.269 22.052 7.915 21.919 7.606ZM9.99996 15H4.99996V11H9.99996V15ZM19.483 7H16.754L15.326 2H17.341L19.483 7ZM7.32596 7L8.75396 2H9.99996V7H7.32596ZM12 2H13.246L14.674 7H12V2ZM4.65896 2H6.67396L5.24596 7H2.51596L4.65896 2Z"
      fill="currentColor"
    />
  </svg>
);

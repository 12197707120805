/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const AZRewardsIconFilled = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM3.28935 7.04521C4.43713 4.43867 7.05714 2.61691 10.1061 2.61691C11.8652 2.61691 13.481 3.22313 14.7541 4.23658L15.0071 3.91874C13.6645 2.84995 11.9601 2.21066 10.1061 2.21066C6.89158 2.21066 4.1284 4.13173 2.91755 6.88149L3.28935 7.04521ZM10.1061 17.3831C7.51916 17.3831 5.24111 16.0717 3.90884 14.0819L3.57127 14.3079C4.97619 16.4063 7.37873 17.7893 10.1061 17.7893C12.2761 17.7893 14.2408 16.9137 15.6606 15.4988L15.3739 15.211C14.0274 16.5528 12.1647 17.3831 10.1061 17.3831Z"
      fill="currentColor"
    />
    <path
      d="M11.1423 11.6498H8.99203L12.7223 8.88868L13.6183 7.32488H9.04904L8.28344 8.66877H10.5314L6.67077 11.4787L5.79928 12.9855H10.3766L11.1423 11.6498Z"
      fill="#FFFFFF"
    />
    <path
      d="M18.505 6.51629C18.3007 6.26272 18.0113 6.09196 17.6905 6.03575L18.1629 5.1724H16.314L15.8742 5.98688C15.2448 6.02316 14.6344 6.21636 14.0987 6.54887C14.0012 6.60941 13.9086 6.6775 13.8217 6.75249H14.6362L13.2353 9.17149L13.1702 9.27737L13.0724 9.35067L12.4046 9.8475C12.4262 9.93421 12.462 10.0168 12.5104 10.0918C12.7372 10.4422 13.0555 10.7238 13.4308 10.9063L13.7973 11.0937C13.9438 11.1624 14.0854 11.2413 14.2208 11.3299C14.2891 11.3797 14.338 11.4517 14.3593 11.5335C14.3816 11.6651 14.3675 11.8002 14.3186 11.9244C14.2618 12.0667 14.1965 12.2054 14.1231 12.3398C13.9983 12.5666 13.8261 12.7639 13.6181 12.9181C13.3713 13.06 13.0877 13.1253 12.8036 13.1054C12.4779 13.1054 12.372 13.0403 12.3313 12.9995C12.2905 12.9588 12.3313 12.7389 12.3313 12.633C12.4499 12.3229 12.5943 12.0232 12.7629 11.7371L12.9177 11.4765H11.8996L11.6471 11.9081L10.8815 13.2357L10.7104 13.5289H10.0914C10.1019 13.7072 10.1549 13.8804 10.2462 14.0339C10.4741 14.3283 10.8066 14.5237 11.1747 14.5796L10.5068 15.8095H12.2742L12.8932 14.6774C13.3591 14.6509 13.818 14.552 14.2534 14.3841C14.6256 14.237 14.9638 14.0153 15.2471 13.7326C15.5644 13.3874 15.838 13.0044 16.0616 12.5923C16.2415 12.2957 16.3916 11.9819 16.5095 11.6556C16.6437 11.308 16.6748 10.9291 16.5991 10.5642C16.5341 10.292 16.3895 10.0454 16.1837 9.85565C15.9652 9.66471 15.7246 9.50064 15.467 9.36696L15.1331 9.20407C14.9658 9.1156 14.8145 9 14.6851 8.86199C14.6254 8.7914 14.6254 8.66923 14.6851 8.49547C14.7321 8.37669 14.7893 8.26223 14.8561 8.15339C14.9587 7.96003 15.1104 7.7971 15.296 7.68099C15.4955 7.57296 15.721 7.52222 15.9475 7.53439C16.0804 7.51679 16.2149 7.55187 16.3222 7.63212C16.3711 7.69728 16.3222 7.81131 16.3222 7.88461C16.2513 8.07635 16.1641 8.26165 16.0616 8.43846L15.9231 8.69909H18L18.0489 8.6095C18.2308 8.31271 18.3835 7.99904 18.505 7.67285C18.6867 7.30874 18.6867 6.8804 18.505 6.51629Z"
      fill="#FFFFFF"
    />
    <path
      d="M6.42634 7.21825C5.33239 7.02543 4.23076 7.51505 3.64082 8.45625L1.0345 12.9929H2.73675L3.46164 11.7305H4.27612L3.55123 12.9929H5.24535L7.68878 8.82277C8.04715 8.08974 7.66434 7.39743 6.42634 7.21825ZM5.75847 9.14041L5.08245 10.3296H4.26797L5.00915 9.02639C5.12498 8.81657 5.35822 8.69995 5.59557 8.73318C5.83177 8.78204 5.91322 8.91236 5.75847 9.16485V9.14041Z"
      fill="#FFFFFF"
    />
  </svg>
);

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const MyWarrantiesFilled = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      d="M19.527 5.126L10.527 0.126C10.224 -0.042 9.85799 -0.042 9.55499 0.126L0.554992 5.126C0.237992 5.303 0.0419922 5.637 0.0419922 6C0.0419922 6.694 0.155993 23 10.042 23C19.928 23 20.042 6.694 20.042 6C20.042 5.637 19.845 5.303 19.527 5.126ZM10.38 16.816L5.29199 13L6.79199 11L9.70299 13.184L13.982 6.338L16.102 7.663L10.38 16.816Z"
      fill="currentColor"
    />
  </svg>
);

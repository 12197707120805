/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const OrderHistoryFilled = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <g clipPath="url(#clip0_2590_5414)">
      <path
        d="M7.01098 4.12402L3.10498 5.86002L12 9.90202L15.439 8.34002L7.01098 4.12402Z"
        fill="currentColor"
      />
      <path
        d="M17.7809 7.274L20.8939 5.86L12.4059 2.087C12.1469 1.971 11.8519 1.971 11.5939 2.087L9.37793 3.073L17.7809 7.274Z"
        fill="currentColor"
      />
      <path
        d="M13 11.644V21.65L21.406 17.914C21.768 17.755 22 17.396 22 17.001V7.55396L13 11.644Z"
        fill="currentColor"
      />
      <path
        d="M11 11.644L2 7.55396V17.001C2 17.397 2.232 17.755 2.594 17.915L11 21.65V11.644ZM4 14.001V11.001L9 13.001V16.001L4 14.001Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2590_5414">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const NavigationHome = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82369 14.765H14.1715V20.7552V22L19.6062 21.9532V20.7552V12.369H21.454C21.954 12.369 22.1932 11.6861 21.8127 11.3267L12.7258 2.3055C12.3128 1.89817 11.6824 1.89817 11.2693 2.3055L2.18245 11.3267C1.81288 11.6861 2.04114 12.369 2.54114 12.369H4.38895V20.7552V21.9532H9.82369V20.7552V14.765Z"
      fill="currentColor"
    />
    <mask id="mask0_2590_5405" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82369 14.765H14.1715V20.7552V22L19.6062 21.9532V20.7552V12.369H21.454C21.954 12.369 22.1932 11.6861 21.8127 11.3267L12.7258 2.3055C12.3128 1.89817 11.6824 1.89817 11.2693 2.3055L2.18245 11.3267C1.81288 11.6861 2.04114 12.369 2.54114 12.369H4.38895V20.7552V21.9532H9.82369V20.7552V14.765Z"
        fill="currentColor"
      />
    </mask>
    <g mask="url(#mask0_2590_5405)"></g>
  </svg>
);

/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { countryCodes } from '@/constants/locale';
import { useFeatureFlag } from '@/features/globalConfig';
import { useLocale } from '@/hooks/useLocale';
import { useAppState } from '@/stores/AppState';
import { useIsValidMobileAppVersion } from '@/hooks/useIsValidMobileAppVersion';

export const useStreamLineSignIn = () => {
  const isValidMobileAppVersion = useIsValidMobileAppVersion('MOBILE_APP_STREAMLINED_FLOW_VERSION');
  const { isMobileApp } = useAppState().state;
  const isMxSite = useLocale() === countryCodes.mx;

  const isSignInV2EnabledFlag = useFeatureFlag('ENABLE_STREAMLINE_SIGNIN') === 'true';
  const newSignInEnabled = useFeatureFlag('NEW_SIGN_IN_ENABLED') === 'true';

  if (isMobileApp) {
    return isSignInV2EnabledFlag && !!isValidMobileAppVersion;
  }

  return isSignInV2EnabledFlag && (!isMxSite || newSignInEnabled);
};

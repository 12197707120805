/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const Avatar = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.16667 8.16667C8.16667 10.4646 10.0354 12.3333 12.3333 12.3333C14.6313 12.3333 16.5 10.4646 16.5 8.16667C16.5 5.86979 14.6313 4 12.3333 4C10.0354 4 8.16667 5.86979 8.16667 8.16667ZM4 19.625V20.6667H20.6667V19.625C20.6667 15.9448 17.2396 13.375 12.3333 13.375C7.42708 13.375 4 15.9448 4 19.625Z"
      fill="currentColor"
    />
  </svg>
);

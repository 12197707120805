/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { SVGProps } from 'react';

export const Located = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/forbid-elements
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.293 17.707L12 18.415L12.707 17.707C12.923 17.491 18 12.365 18 8C18 4.692 15.309 2 12 2C8.691 2 6 4.692 6 8C6 12.365 11.077 17.491 11.293 17.707ZM12 22C7.184 22 2 20.749 2 18C2 17.046 2.648 15.716 5.734 14.799L6.303 16.716C4.4 17.282 4 17.902 4 18C4 18.515 6.75 20 12 20C17.25 20 20 18.515 20 18C20 17.902 19.602 17.284 17.711 16.721L18.281 14.804C21.355 15.719 22 17.047 22 18C22 20.749 16.816 22 12 22ZM14 8C14 9.10457 13.1046 10 12 10C10.8954 10 10 9.10457 10 8C10 6.89543 10.8954 6 12 6C13.1046 6 14 6.89543 14 8Z"
      fill="currentColor"
    />
  </svg>
);
